<template>
    <div>
      <Breadcrumbs main="" title="Referrals" />
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
                  <b-row class="col-md-12 col-12">
                    <b-col>
                      <b-button variant="primary" size="lg" @click="payout_modal = true"> Referral Payout  </b-button>
                    </b-col>
                  </b-row>
                <b-row class="mt-3">
                <b-col class="col-md-3 col-12">
                    <b-row class="col-md-12 col-12">
                        <b-col>
                            Select Date from
                        </b-col>
                    </b-row>
                    <b-row class="col-md-12 col-12">
                        <b-col>
                            <b-form-input size="sm" type="datetime-local" v-model="input.sdate"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-4 col-12">
                    <b-row class="col-md-12 col-12">
                        <b-col>
                            Select Date to
                        </b-col>
                    </b-row>
                    <b-row class="col-md-12 col-12">
                        <b-col class="col-md-6 col-12">
                            <b-form-input size="sm" type="datetime-local" v-model="input.edate"></b-form-input>
                        </b-col>
                        <b-col class="col-md-6 col-12 text-center mt-md-0 mt-2">
                            <b-button variant="primary" @click="searchLogs()"> Filter </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                </b-row>
                <b-row>
                <div class="col-md-12 mt-5" v-if="loading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                    <div class="loader-box">
                      <div class="loader-30"></div>
                    </div>
                </div> 
                </b-row>
                <div v-if="(items.length > 0)">
                  <b-row class="mt-3">
                  <b-col md="6" class="mb-3">
                    <b-input-group class="datatable-btn">
                      <b-form-input
                        v-model="filter"
                        placeholder="Type to Search"
                        type="search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
  
                  <b-col md="6" class="mb-3">
                    <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                    >
                      <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                        @change="filterLogs"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                
                <div class="table-responsive datatable-vue">
                  <b-table
                    show-empty
                    stacked="md"
                    :items="items"
                    :filter="filter"
                    :fields="tablefields"
                    @filtered="onFiltered"
                    :per-page="perPage"
                  >
                    <template #table-caption>Activity List.</template>

                    <template #cell(userid)="data">
                    <b class="" v-if="user_opt.filter(t=> t.value === data.item.userid)">
                      {{ user_opt.filter(t=> t.value === data.item.userid)[0].text }} - ({{ user_opt.filter(t=> t.value === data.item.userid)[0].email  }})</b>
                    </template>
                    
                    <template #cell(refererid)="data">
                    <b class="" v-if="user_opt.filter(t=> t.value === data.item.refererid)">
                      {{ user_opt.filter(t=> t.value === data.item.refererid)[0].text }} - ({{ user_opt.filter(t=> t.value === data.item.refererid)[0].email  }})</b>
                    </template>

                    <template #cell(planid)="data">
                    <b class="" v-if="plans.filter(t=> t._id === data.item.planid)">
                      {{ plans.filter(t=> t._id === data.item.planid)[0].plan_name }}</b>
                    </template>
                  
                  </b-table>
                </div>
              </div>
              <div v-else>
                    <b-row v-if="(loading != true)" class="mt-5">
                        <b-col class="text-center">
                            No data Found
                        </b-col>
                    </b-row>
              </div>
              <b-col md="6" class="mt-3 p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="filterLogs('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="items.length >= 1" @click="filterLogs('next')">Next &raquo;</a>
                </div>
              </b-col>      
          </div>
        </div>
      </div>

      <b-modal id="modal-monitor" size="lg" @hidden="closeModal" title="Referral Payment" class="theme-modal" v-model="payout_modal" no-close-on-backdrop hide-footer>
        <div class="p-5">
        <b-row>
          <b-col class="col-md-3 col-12 font-weight-bold">
            Select Referer 
          </b-col>
          <b-col class="col-md-5 col-12">
            <b-form-select name="type" size="sm" v-model="payment.refererid" :options="user_opt"  required></b-form-select>
          </b-col>
        </b-row><hr>
        <b-row>
          <b-col cols="3" class="col-md-3 col-12 font-weight-bold">
            Select Date from
          </b-col>
          <b-col class="col-md-5 col-12">
            <b-form-input size="sm" type="datetime-local" v-model="payment.sdate"></b-form-input>
          </b-col>
        </b-row><hr>
        <b-row>
          <b-col cols="3" class="col-md-3 col-12 font-weight-bold">
            Select Date to
          </b-col>
          <b-col class="col-md-5 col-12">
            <b-form-input size="sm" type="datetime-local" v-model="payment.edate"></b-form-input>
          </b-col>
        </b-row><hr>
        <b-row>
          <b-col class="text-center">
            <b-button size="sm" variant="primary" @click="getReferralPayment" :disabled="payamount">Get Payment Info</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-5 p-3" v-show="payamount">
          <b-col class="col-md-3 col-12 font-weight-bold">
            <label class="f-w-900 text-success">Payable Amount =  {{ payable_amount }} </label>
          </b-col>
          <b-col class="col-md-5 col-12">
            <b-button size="xl" variant="success" @click="PayOut()">Pay</b-button>
            <b-button size="xl" variant="danger" class="ml-2" @click="payamount = false">Cancel</b-button>
          </b-col>
        </b-row>
        </div>
      </b-modal>
    </div>
  </template>
  
  
  <script>
  import UserController from "../../../services/userController"
  import ManagerController from "../../../services/managerController"
  import moment from "moment";
  export default {
      name:'Users',
      data(){
          return{
              payout_modal:false,
              input:{},
              user_opt:[{"value":null,"text":'select referrer'}],
              plans:[],
              items:[],
              totalRows:0,
              filter:null,
              currentPage: 1,
              perPage: 50,
              pageOptions: [50, 100, 200, 500],
              loading:false,
              userinfo :[],
              user_data:[],
              tablefields:[
                { key: "userid", label: "User", sortable: true },
                { key: "refererid", label: "Referer Id", sortable: true },
                { key: "amount", label: "Amount", sortable: true },
                { key: "chargePer", label: "Charge Per", sortable: true },
                { key: "planid", label: "Plan", sortable: true },
                { key: "createdAt", label: "CreatedAt", sortable: true },
              ],
              payment:{refererid:null},
              payable_amount:0,
              payamount:false
          }
      },
      mounted(){
        this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'))
          this.getUsers()
          this.getPlans()
      },
      methods: {
          async getPlans(){
            this.loading = true;
              this.items = []
              let response = await ManagerController.getPlans()
              if (response.result) {
                  this.plans = response.data.reverse();
              }
              this.loading = false;
          },
          async getUsers(){
              this.user_data = []
              let response = await UserController.getAllUsers()
              if (response.result) {
                  this.user_data = response.message.filter(t=>{
                      if(t.role != 'manager'){
                          return true;
                      }
                  });
            this.user_data.map((t)=>{
                this.user_opt.push({"value":t._id,"text":t.username,"email":t.email})
            })
                  
            }
            else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
          },
          onFiltered(filteredItems) {
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        },
        changeDateFormate(d) {
        return moment(d).format("D/M/YYYY, HH A");
        },
        searchLogs(){
            this.currentPage = 1
            this.filterLogs()
        },
        async filterLogs(txt){
            this.loading = true
            if(txt == 'next'){this.currentPage ++}
            if(txt == 'prev'){
                if(this.currentPage != 1){
                this.currentPage --
                }
            }
            this.items = []
            let payload = {
            "currentPage": this.currentPage,"pageSize": this.perPage,
            "filters" : {   
                    "sdate": this.input.sdate,
                    "edate": this.input.edate
                }
            }
            let response = await ManagerController.getrefLogs(payload)
            if(response.result){
                this.items = response.data
                // this.items =  [
                //     {
                //         "_id": "6436b0f2d852761b197c5c67",
                //         "userid": "63ff32fcfc72e21be51c032b",
                //         "refererid": "64198ccb62ea1a2d36d87f97",
                //         "amount": 2,
                //         "chargePer": 20,
                //         "planid": "63ff37a4fc72e21be51c034d",
                //         "createdAt": "2023-04-12T13:24:02.723Z",
                //         "updatedAt": "2023-04-12T13:24:02.723Z",
                //         "__v": 0
                //     }
                // ] 
            }
            this.loading = false
        },
        async getReferralPayment(){
          if(this.payment.refererid){
          this.payamount = false
          let response = await ManagerController.getReferralPayment(this.payment)
            if(response.result){
              this.payable_amount = response.data.amount
            }
            this.payamount = true
          }
        },
        async PayOut(){
          if(this.payment.refererid){
            let response = await ManagerController.PayOut(this.payment)
            if(response.result){
              this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
              this.payout_modal = false
            }
            else{
              this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
          }
        },
        closeModal(){
          this.payout_modal = false
          this.payamount = false
          this.payment = {refererid:null}
        }
      }
  }
  </script>


<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd;cursor: pointer;}
</style>